.BasicButton {
  button {
    border: none;
    outline: none;
    background-color: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 4px 8px;
    border-radius: 4px;
    transition: all 0.2s ease-in-out;

    font-size: 13px;
    font-style: normal;
    text-transform: capitalize;

    &.primary {
      &.filled {
        line-height: 20px; /* 153.846% */
        letter-spacing: -0.13px;

        &.clickable {
          font-weight: 500;
          background: var(--purple-500, #7244fb);
          color: var(--color-text-inverse, var(--netural-l-0, #fff));

          &:hover {
            background: #6137df;
          }

          &:active {
            background: #5130b2;
          }
        }

        &.disabled {
          cursor: default;
          background-color: #dedfe2;
          color: #b9bdc3;
          font-weight: 400;
        }
      }
    }

    &.secondary {
      &.outline {
        font-weight: 400;
        line-height: 20px; /* 153.846% */
        letter-spacing: -0.026px;

        &.clickable {
          border: 1px solid var(--color-border-02, #d3d5da);
          background: var(--color-button-secondary-01, #fff);
          color: var(--color-text-primary, #1c293e);
          background: var(--color-button-secondary-01, #fff);

          &:hover {
            background: var(--color-button-secondary-hover-01, #eceef0);
          }

          &:active {
            background: #dedfe2;
          }
        }

        &.disabled {
          cursor: default;
          border: 1px solid var(--color-border-disable, #eceef0);
          background: var(--color-button-secondary-01, #fff);
          color: var(--color-text-disabled, var(--netural-l-300, #b9bdc3));
        }
      }
    }
  }
}
