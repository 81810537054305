.Sitemap {
  width: 99vw;
  height: 60vh;
  min-height: 400px;
  border-radius: 10px 10px 0px 0px;
  background-color: #2a2d37;
  color: #ffffff;

  margin-right: 1vw;

  .sitemap_container {
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;

    .left_container {
      width: 40%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 36px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: -0.144px;

      .logo {
        width: 50%;
      }
    }
    .right_container {
      width: 60%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;

      .sitemap_box {
        display: flex;
        flex-direction: column;
        height: 300px;

        .category {
          margin-bottom: 40px;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: -0.064px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
        .subCategory {
          color: #9a9ba0;
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.026px;
          margin-bottom: 5px;
          // cursor: pointer;
          // &:hover {
          //   text-decoration: underline;
          // }
        }
      }
    }
  }

  .bottom_text_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;

    .bottom_text {
      height: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #a4a4a4;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 153.846% */
      letter-spacing: -0.026px;
    }
    .bottom_text_link {
      @extend .bottom_text;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
