.MenuPopover {
  font-style: normal;

  .backdrop {
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
    opacity: 0.3;
    background: #000;
    z-index: 50;
  }

  .container {
    position: fixed;
    z-index: 101;
    opacity: 1;
    border-radius: 4px;
    background: var(--netural-l-0, #fff);
    box-shadow: 0px 2px 6px 0px #0000004d;
    width: fit-content;
    padding: 28px;

    display: flex;
    flex-direction: column;
    gap: 12px;

    .wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
      cursor: pointer;

      &:hover > .title {
        color: #1c293e;
      }

      .arrow {
        opacity: 0;
        margin-top: 1px;
      }

      &:hover > .arrow {
        transition: all 0.2s ease-in-out;
        transform: translateX(4px);
        opacity: 1;
      }

      .icon {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 2px;
      }

      .title {
        color: #7a828e;
        font-size: 15px;
        font-weight: 500;
        line-height: 24px; /* 160% */
        letter-spacing: -0.06px;
        transition: all 0.2s ease-in-out;
      }

      .subItem_container {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .subItem {
        .subtitle {
          cursor: pointer;
          color: var(--color-text-primary, var(--netural-l-800, #1c293e));
          font-size: 13px;
          font-weight: 400;
          line-height: 20px; /* 153.846% */
          letter-spacing: -0.026px;

          display: flex;
          gap: 8px;
          align-items: center;
        }

        .icon {
          opacity: 0;
          margin-top: 1px;
        }

        .subtitle:hover > .icon {
          transition: all 0.2s ease-in-out;
          transform: translateX(4px);
          opacity: 1;
        }
      }
    }
  }
}
