.SinglePagePopover {
  font-style: normal;
  display: flex;
  gap: 12px;
  text-align: left;

  .iconBox {
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    // background-color: #eceef0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .content {
    width: min-content;

    .title_wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 28px;
      text-align: left;
      width: 90%;

      .title {
        display: flex;
        gap: 4px;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        height: 40px;
        margin-bottom: 16px;
        color: #1c293e;
        font-size: 18px;
        font-weight: 700;
        line-height: 24px; /* 133.333% */
        letter-spacing: -0.036px;
        transition: all 0.2s ease-in-out;

        .arrow {
          width: 22px;
          height: 22px;
          opacity: 0;
        }

        &:hover > .arrow {
          transition: all 0.2s ease-in-out;
          transform: translateX(4px);
          opacity: 1;
        }
      }

      .description {
        color: #686a71;
        font-size: 15px;
        font-weight: 400;
        line-height: 24px; /* 160% */
        letter-spacing: -0.03px;
      }
    }

    hr {
      width: 95%;
      height: 1px;
      border: 0;
      background-color: #dedfe2;
      margin-bottom: 28px;
    }

    .item_container {
      display: grid;
      gap: 20px;

      .item {
        display: flex;
        flex-direction: column;
        width: 200px;
        margin-bottom: 20px;
        transition: all 0.2s ease-in-out;

        &.clickable {
          cursor: pointer;

          &:hover > .subtitle_wrapper .arrow {
            transform: translateX(4px);
          }
        }

        .subtitle_wrapper {
          display: flex;
          gap: 8px;
          .subtitle {
            color: #1c293e;
            font-size: 15px;
            font-weight: 600;
            line-height: 24px; /* 160% */
            letter-spacing: -0.06px;
            margin-bottom: 12px;
          }

          .arrow {
            width: 13px;
            height: 13px;
            transition: all 0.2s ease-in-out;
          }
        }

        .description {
          font-size: 13px;
          font-weight: 400;
          line-height: 20px; /* 153.846% */
          letter-spacing: -0.026px;
          color: #86888d;
          color: #434d5f;
        }
      }

      &.two_column {
        grid-template-columns: fit-content(100%) fit-content(100%);
      }

      &.three_column {
        grid-template-columns: fit-content(100%) fit-content(100%) fit-content(
            100%
          );
      }
    }

    .page_button {
      text-align: right;
      display: flex;
      justify-content: flex-end;
      margin: 0 4px 4px 0;
    }
  }
}
