.TextArrowButton {
  display: flex;
  gap: 8px;
  cursor: pointer;
  transition: all 0.2s 0.1s ease-in-out;
  color: #9ea4ac;

  &:hover {
    color: #86888d;
  }

  span {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 160% */
    letter-spacing: -0.03px;
    text-decoration-line: underline;
  }

  .icon {
    transition: all 0.2s 0.1s ease-in-out;
    width: 12px;
    height: 12px;
  }

  &:hover > .icon {
    transform: translateX(4px);
  }
}
