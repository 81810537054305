@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.5/dist/web/static/pretendard.css");
@import url("https://fonts.googleapis.com/earlyaccess/notosanskr.css");
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Noto Sans KR", sans-serif, Pretendard, Roboto, "Segoe UI",
    "Malgun Gothic", sans-serif !important;
}

body {
  width: 100vw;
  overflow-x: hidden;
}

:root {
  --background-color: #b6b6b6;
  --colorful-text-background: linear-gradient(
    91deg,
    #cc89ed 2.44%,
    #a7aaf5 36.13%,
    #85a4f3 64.9%,
    #8092d4 99.61%
  );
  --main-page-division-height: 90vh;
}

button {
  border: none;
  outline: none;
  background-color: inherit;
  cursor: pointer;
}
