.MultiPagePopover {
  .container {
    font-style: normal;
    display: flex;
    gap: 12px;
    text-align: left;

    display: grid;
    gap: 28px;
    grid-template-columns: fit-content(100%) fit-content(100%);

    .wrapper {
      width: 216px;
      height: fit-content;
      height: 88px;
      text-align: left;
      margin-bottom: 20px;
      display: flex;
      gap: 8px;

      .icon {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-top: 3px;
      }

      .content_wrapper {
        .title_wrapper {
          display: flex;
          gap: 8px;
          cursor: pointer;
          margin-bottom: 20px;

          .title {
            font-size: 15px;
            font-weight: 600;
            line-height: 24px; /* 160% */
            letter-spacing: -0.06px;
            color: #1c293e;
          }

          .arrow {
            width: 15px;
            height: 15px;
            opacity: 0;
            margin-top: 1px;
          }

          &:hover > .arrow {
            transition: all 0.2s ease-in-out;
            transform: translateX(4px);
            opacity: 1;
          }
        }

        // &:nth-of-type(1) > :nth-child(1) {
        //   color: rgba(116, 120, 239, 0.9);
        // }

        // &:nth-of-type(2) > :nth-child(1) {
        //   color: rgba(61, 110, 236, 0.85);
        // }

        // &:nth-of-type(3) :nth-child(1) {
        //   color: rgba(53, 82, 186, 0.8);
        // }

        // &:nth-of-type(4) :nth-child(1) {
        //   color: rgba(22, 152, 152, 0.75);
        // }

        // &:nth-of-type(5) :nth-child(1) {
        //   color: #5eb9a9;
        // }

        // &:nth-of-type(6) :nth-child(1) {
        //   color: rgba(63, 192, 209, 0.65);
        // }

        .description {
          color: var(--netural-l-500, #7a828e);
          font-size: 13px;
          font-weight: 400;
          line-height: 20px; /* 153.846% */
          letter-spacing: -0.026px;
        }

        .subItem_container {
          display: flex;
          flex-direction: column;
          gap: 8px;
        }

        .subItem {
          .subtitle {
            cursor: pointer;
            color: var(--color-text-primary, var(--netural-l-800, #1c293e));
            font-size: 13px;
            font-weight: 400;
            line-height: 20px; /* 153.846% */
            letter-spacing: -0.026px;

            display: flex;
            gap: 8px;

            .icon {
              width: 13px;
              height: 13px;
              opacity: 0;
              margin-top: 1px;
            }
          }

          .subtitle:hover > .icon {
            transition: all 0.2s ease-in-out;
            transform: translateX(4px);
            opacity: 1;
          }
        }
      }
    }
  }
}
