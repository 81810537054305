.swtichContainer {
  display: flex;
  align-items: center;
}
.switchCheckBox {
  display: none;
}

.switchCheckBox + .switchLabel {
  cursor: pointer;
  position: relative;
  width: 28px;
  height: 16px;
  border-radius: 12px;
  background-color: #d3d5da;

  &::after {
    content: "";
    top: 2px;
    left: 2px;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 12px;
    background-color: #ffffff;
    z-index: 1;
    transition: transform ease-out 0.2s;
  }
}

.switchCheckBox:checked + .switchLabel {
  width: 28px;
  height: 16px;
  border-radius: 12px;
  background-color: #46adf7;

  &::after {
    transform: translate(12px);
  }
}

.switchDescription {
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  vertical-align: middle;
  letter-spacing: -0.002em;
}

.switchWithLabelBox {
  height: 24px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.switchCheckBox + .switchLabel + .checked {
  display: none;
}

.switchCheckBox:checked + .switchLabel + .checked {
  display: block;
}

.switchCheckBox:checked + .switchLabel + .checked + .notChecked {
  display: none;
}
