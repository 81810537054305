.container {
  position: fixed;
  right: 8px;
  bottom: 8px;
  z-index: 200;

  display: flex;
  flex-direction: column;
  border-radius: 4px;
  background: var(--netural-l-0, #fff);
  box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);

  color: var(--color-text-primary, #1c293e);
  font-style: normal;

  width: 532px;
  padding: 12px 16px;
  gap: 8px;

  &.manage_form {
    padding: 16px;
  }

  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px; /* 142.857% */
    letter-spacing: -0.028px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px; /* 133.333% */
    color: var(--color-text-primary, #1c293e);
  }

  .content {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
    letter-spacing: -0.024px;
    margin-bottom: 8px;

    .info_button {
      cursor: pointer;
      margin-left: 4px;
      text-decoration: underline;

      font-weight: 500;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.12px;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: var(--purple-600, #6137df);
      }
    }
  }

  .button_container {
    display: flex;
    gap: 8px;
    align-self: flex-end;
  }
}
