.Navbar {
  position: fixed;
  z-index: 100;
  width: 100vw;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0px 60px;
  backdrop-filter: blur(15px);

  .nav_container {
    display: flex;
    align-items: center;

    .logo {
      margin-right: 60px;
      width: 300px;
      height: fit-content;
      cursor: pointer;
    }

    .menu_container {
      display: flex;

      align-items: center;
      gap: 52px;

      .menu {
        height: 100%;
        height: 88px;
        display: flex;
        align-items: center;

        &:hover .button {
          background: linear-gradient(
            91deg,
            #cc89ed 2.44%,
            #a7aaf5 36.13%,
            #85a4f3 64.9%,
            #8092d4 99.61%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }

        .container {
          position: relative;
          text-align: center;

          .button {
            color: #1c293e;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            letter-spacing: -0.04px;
            transition: all 0.2s ease-in-out;
          }

          .popOver {
            position: absolute;
            padding-top: 4px;
            top: 48px;
            left: -10%;
          }
        }
      }
    }
  }

  .right_container {
    display: flex;
    align-items: center;
    gap: 52px;

    .icon_container {
      display: flex;
      gap: 28px;

      .icon {
        cursor: pointer;
      }
    }

    .button_container {
      display: flex;
      gap: 20px;
    }
  }
}
