.CookieManageForm {
  display: flex;
  flex-direction: column;
  color: var(--color-text-primary, #1c293e);
  font-style: normal;

  .header {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px; /* 160% */
    letter-spacing: -0.06px;

    .icon {
      width: 28px;
      height: 28px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      margin-top: 1px;
      color: #5e6777;

      &:hover {
        color: #7a828e;
      }
    }
  }

  .item_container {
    display: flex;
    flex-direction: column;
    padding: 12px;

    .item_wrapper {
      display: flex;
      flex-direction: column;

      &::after {
        width: 100%;
        background-color: #d3d5da;
        content: "";
        height: 1px;
        margin: 16px 0px;
      }

      h3 {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        letter-spacing: -0.028px;
        margin-bottom: 4px;
      }

      .content {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        span {
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px; /* 133.333% */
          letter-spacing: -0.024px;
          width: 85%;
        }
      }
    }
  }

  .info {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px; /* 145.455% */
    letter-spacing: -0.022px;

    .info_button {
      font-size: 11px;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      letter-spacing: -0.024px;
      text-decoration-line: underline;
      cursor: pointer;
      margin-left: 4px;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: var(--purple-600, #6137df);
      }
    }
  }

  .button_container {
    display: flex;
    gap: 8px;
    align-self: flex-end;
  }
}
